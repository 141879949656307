import React from 'react';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import InteractiveSection from '../components/InteractiveSection';
import SportsData from '../components/SportsData';
import NewsCarousel from '../components/NewsCarousel';
import './Home.css';

const Home = () => {
  return (
    <main className="home">
      <HeroSection />
      <NewsCarousel />
      <FeaturesSection />
      <SportsData />
      <InteractiveSection />
    </main>
  );
};

export default Home;
