import React from 'react';
import './FeaturesSection.css';

const FeatureCard = ({ icon, title, description }) => (
  <div className="feature-card">
    <div className="feature-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const FeaturesSection = () => {
  const features = [
    {
      icon: '📰',
      title: 'Actualités Sportives',
      description: 'Restez informé des dernières nouvelles et mises à jour dans le monde du sport. Nos experts couvrent tous les événements majeurs.'
    },
    {
      icon: '📊',
      title: 'Estimation de Performance',
      description: 'Suivez et analysez vos performances sportives. Notre outil vous aide à atteindre vos objectifs sportifs.'
    },
    {
      icon: '🎯',
      title: 'Quiz Sportif',
      description: 'Découvrez le sport qui vous correspond le mieux grâce à notre quiz interactif personnalisé.'
    }
  ];

  return (
    <section className="features-section" id="features">
      <div className="container">
        <div className="features-grid">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
