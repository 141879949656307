import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container navbar-content">
        <Link to="/" className="logo">
          Sport Index
        </Link>
        <div className="nav-links">
          <a href="https://sport-index.fr/blog/" className="nav-link">
            Blog
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
