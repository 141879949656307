import React, { useState } from 'react';
import './InteractiveSection.css';

const InteractiveSection = () => {
  const [activeTab, setActiveTab] = useState('news');
  const [selectedSport, setSelectedSport] = useState('all');
  const [performanceData, setPerformanceData] = useState({
    sport: '',
    duration: '',
    goal: ''
  });
  const [quizAnswers, setQuizAnswers] = useState({});

  const sports = ['Football', 'Basketball', 'Tennis', 'Running', 'Natation'];

  const articles = [
    {
      title: 'Les dernières tendances en football',
      sport: 'Football',
      excerpt: 'Découvrez les nouvelles stratégies adoptées par les équipes de haut niveau...'
    },
    {
      title: 'Guide du basketball moderne',
      sport: 'Basketball',
      excerpt: 'Comment le jeu a évolué ces dernières années...'
    },
    // Ajoutez plus d'articles ici
  ];

  const handlePerformanceSubmit = (e) => {
    e.preventDefault();
    // Logique d'estimation des performances
    alert('Estimation en cours de développement');
  };

  const handleQuizSubmit = (e) => {
    e.preventDefault();
    // Logique de recommandation
    alert('Recommandation en cours de développement');
  };

  return (
    <section className="interactive-section">
      <div className="container">
        <div className="tabs">
          <button
            className={`tab-button ${activeTab === 'news' ? 'active' : ''}`}
            onClick={() => setActiveTab('news')}
          >
            Actualités Sportives
          </button>
          <button
            className={`tab-button ${activeTab === 'performance' ? 'active' : ''}`}
            onClick={() => setActiveTab('performance')}
          >
            Estimation de Performance
          </button>
          <button
            className={`tab-button ${activeTab === 'quiz' ? 'active' : ''}`}
            onClick={() => setActiveTab('quiz')}
          >
            Quiz Sportif
          </button>
        </div>

        <div className="tab-content">
          {activeTab === 'news' && (
            <div className="news-section">
              <div className="sport-filter">
                <select
                  value={selectedSport}
                  onChange={(e) => setSelectedSport(e.target.value)}
                >
                  <option value="all">Tous les sports</option>
                  {sports.map((sport) => (
                    <option key={sport} value={sport}>
                      {sport}
                    </option>
                  ))}
                </select>
              </div>
              <div className="articles-grid">
                {articles
                  .filter(
                    (article) =>
                      selectedSport === 'all' || article.sport === selectedSport
                  )
                  .map((article, index) => (
                    <div key={index} className="article-card">
                      <h3>{article.title}</h3>
                      <p>{article.excerpt}</p>
                      <span className="sport-tag">{article.sport}</span>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {activeTab === 'performance' && (
            <div className="performance-section">
              <form onSubmit={handlePerformanceSubmit}>
                <div className="form-group">
                  <label>Sport</label>
                  <select
                    value={performanceData.sport}
                    onChange={(e) =>
                      setPerformanceData({
                        ...performanceData,
                        sport: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Sélectionnez un sport</option>
                    {sports.map((sport) => (
                      <option key={sport} value={sport}>
                        {sport}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Durée d'entraînement (heures/semaine)</label>
                  <input
                    type="number"
                    value={performanceData.duration}
                    onChange={(e) =>
                      setPerformanceData({
                        ...performanceData,
                        duration: e.target.value
                      })
                    }
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Objectif</label>
                  <textarea
                    value={performanceData.goal}
                    onChange={(e) =>
                      setPerformanceData({
                        ...performanceData,
                        goal: e.target.value
                      })
                    }
                    required
                  />
                </div>
                <button type="submit" className="submit-button">
                  Estimer ma performance
                </button>
              </form>
            </div>
          )}

          {activeTab === 'quiz' && (
            <div className="quiz-section">
              <form onSubmit={handleQuizSubmit}>
                <div className="quiz-question">
                  <label>Quel est votre niveau de condition physique actuel ?</label>
                  <select
                    value={quizAnswers.fitnessLevel}
                    onChange={(e) =>
                      setQuizAnswers({
                        ...quizAnswers,
                        fitnessLevel: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Sélectionnez une réponse</option>
                    <option value="beginner">Débutant</option>
                    <option value="intermediate">Intermédiaire</option>
                    <option value="advanced">Avancé</option>
                  </select>
                </div>
                <div className="quiz-question">
                  <label>Préférez-vous les sports individuels ou d'équipe ?</label>
                  <select
                    value={quizAnswers.preference}
                    onChange={(e) =>
                      setQuizAnswers({
                        ...quizAnswers,
                        preference: e.target.value
                      })
                    }
                    required
                  >
                    <option value="">Sélectionnez une réponse</option>
                    <option value="individual">Individuels</option>
                    <option value="team">D'équipe</option>
                    <option value="both">Les deux</option>
                  </select>
                </div>
                <button type="submit" className="submit-button">
                  Obtenir ma recommandation
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default InteractiveSection;
