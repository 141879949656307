import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import './HeroSection.css';

const SportsScene = () => {
  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <mesh position={[0, 0, 0]} rotation={[0, 0, 0]}>
        <sphereGeometry args={[1, 32, 32]} />
        <meshStandardMaterial color="#2563eb" />
      </mesh>
    </>
  );
};

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Votre Guide Ultime pour Tous les Sports</h1>
        <p>
          Découvrez, comparez et améliorez vos performances sportives grâce à nos outils interactifs
        </p>
        <a href="#actualites" className="cta-button">
          Découvrez Nos Actualités
        </a>
      </div>
      <div className="hero-canvas">
        <Canvas camera={{ position: [0, 0, 5] }}>
          <OrbitControls enableZoom={false} />
          <SportsScene />
        </Canvas>
      </div>
    </section>
  );
};

export default HeroSection;
