import React, { useState, useEffect } from 'react';
import './SportsData.css';

const SportsData = () => {
  const [sportsData, setSportsData] = useState({
    sports: [],
    generalStats: {},
    healthBenefits: [],
    equipmentRecommendations: {}
  });
  const [selectedSport, setSelectedSport] = useState(null);
  const [activeTab, setActiveTab] = useState('statistics');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/data/sports-data.json')
      .then(response => response.json())
      .then(data => {
        setSportsData(data);
        setSelectedSport(data.sports[0]);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error loading sports data:', error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Chargement des données...</p>
      </div>
    );
  }

  if (!selectedSport) {
    return (
      <div className="error-container">
        <p>Aucune donnée disponible</p>
      </div>
    );
  }

  return (
    <section className="sports-data-section">
      <div className="container">
        <h2 className="section-title">Données Sportives</h2>
        
        <div className="sports-navigation">
          {sportsData.sports.map(sport => (
            <button
              key={sport.id}
              className={`sport-button ${selectedSport.id === sport.id ? 'active' : ''}`}
              onClick={() => setSelectedSport(sport)}
            >
              {sport.name}
            </button>
          ))}
        </div>

        <div className="sport-content">
          <div className="sport-header">
            <h3>{selectedSport.name}</h3>
            <div className="tabs">
              <button
                className={`tab ${activeTab === 'statistics' ? 'active' : ''}`}
                onClick={() => setActiveTab('statistics')}
              >
                Statistiques
              </button>
              <button
                className={`tab ${activeTab === 'federation' ? 'active' : ''}`}
                onClick={() => setActiveTab('federation')}
              >
                Fédération
              </button>
              <button
                className={`tab ${activeTab === 'competitions' ? 'active' : ''}`}
                onClick={() => setActiveTab('competitions')}
              >
                Compétitions
              </button>
              <button
                className={`tab ${activeTab === 'facilities' ? 'active' : ''}`}
                onClick={() => setActiveTab('facilities')}
              >
                Installations
              </button>
            </div>
          </div>

          <div className="tab-content">
            {activeTab === 'statistics' && selectedSport.statistics && (
              <div className="statistics-content">
                <div className="stat-grid">
                  {Object.entries(selectedSport.statistics).map(([key, value]) => (
                    <div key={key} className="stat-item">
                      <h4>{key.replace(/([A-Z])/g, ' $1').trim()}</h4>
                      <p>{value}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'federation' && selectedSport.federation && (
              <div className="federation-content">
                <div className="federation-info">
                  <h4>{selectedSport.federation.name}</h4>
                  <p><strong>Fondée en :</strong> {selectedSport.federation.founded}</p>
                  <p><strong>Siège :</strong> {selectedSport.federation.headquarters}</p>
                  <p><strong>Membres :</strong> {selectedSport.federation.members}</p>
                  <a
                    href={selectedSport.federation.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="federation-link"
                  >
                    Visiter le site web
                  </a>
                </div>
              </div>
            )}

            {activeTab === 'competitions' && selectedSport.competitions && (
              <div className="competitions-content">
                <div className="competitions-grid">
                  {selectedSport.competitions.map((competition, index) => (
                    <div key={index} className="competition-card">
                      <h4>{competition.name}</h4>
                      <p><strong>Type :</strong> {competition.type}</p>
                      <p><strong>Niveau :</strong> {competition.level}</p>
                      <p>
                        <strong>
                          {competition.teams ? 'Équipes' : 'Participants'} :
                        </strong>{' '}
                        {competition.teams || competition.participants}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'facilities' && selectedSport.facilities && (
              <div className="facilities-content">
                <div className="facilities-grid">
                  {Object.entries(selectedSport.facilities).map(([key, value]) => (
                    <div key={key} className="facility-item">
                      <h4>{key.replace(/([A-Z])/g, ' $1').trim()}</h4>
                      <p>{value}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {sportsData.generalStats && (
          <div className="general-stats">
            <h3>Statistiques Générales du Sport en France</h3>
            <div className="stats-grid">
              {Object.entries(sportsData.generalStats).map(([key, value]) => (
                <div key={key} className="general-stat-item">
                  <h4>{key.replace(/([A-Z])/g, ' $1').trim()}</h4>
                  <p>{value}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {sportsData.healthBenefits && (
          <div className="health-benefits">
            <h3>Bienfaits du Sport</h3>
            <div className="benefits-grid">
              {sportsData.healthBenefits.map(category => (
                <div key={category.category} className="benefit-category">
                  <h4>{category.category}</h4>
                  <ul>
                    {category.benefits.map((benefit, index) => (
                      <li key={index}>{benefit}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SportsData;
