import React, { useState, useEffect } from 'react';
import './NewsCarousel.css';

const NewsCarousel = () => {
  const [news, setNews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const extractImageAndText = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    
    // Trouver l'image
    const img = div.querySelector('img');
    const imgSrc = img ? img.src : null;
    const imgAlt = img ? img.alt : '';
    
    // Nettoyer le texte
    if (img) img.remove();
    const cleanText = div.textContent.trim();
    
    return {
      imgSrc,
      imgAlt,
      text: cleanText
    };
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://dwh.lequipe.fr/api/edito/rss?path=/');
        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, 'text/xml');
        const items = xmlDoc.querySelectorAll('item');
        
        const newsItems = Array.from(items).map(item => {
          const description = item.querySelector('description')?.textContent || '';
          const { imgSrc, imgAlt, text } = extractImageAndText(description);
          
          return {
            title: item.querySelector('title')?.textContent || '',
            description: text,
            image: {
              src: imgSrc,
              alt: imgAlt
            },
            link: item.querySelector('link')?.textContent || '',
            pubDate: new Date(item.querySelector('pubDate')?.textContent || '').toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
          };
        });

        setNews(newsItems);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des actualités:', error);
        setIsLoading(false);
      }
    };

    fetchNews();

    const interval = setInterval(() => {
      setCurrentIndex(current => (current + 1) % (news.length || 1));
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const goToNext = () => {
    setCurrentIndex(current => (current + 1) % news.length);
  };

  const goToPrevious = () => {
    setCurrentIndex(current => (current - 1 + news.length) % news.length);
  };

  if (isLoading) {
    return (
      <section className="news-carousel">
        <div className="container">
          <div className="news-loading">
            <p>Chargement des actualités...</p>
          </div>
        </div>
      </section>
    );
  }

  if (news.length === 0) {
    return (
      <section className="news-carousel">
        <div className="container">
          <div className="news-error">
            <p>Aucune actualité disponible pour le moment.</p>
          </div>
        </div>
      </section>
    );
  }

  const currentNews = news[currentIndex];

  return (
    <section className="news-carousel">
      <div className="container">
        <h2 className="news-title">Actualités Sportives</h2>
        
        <div className="news-container">
          <button 
            className="nav-button prev" 
            onClick={goToPrevious}
            aria-label="Article précédent"
          >
            ‹
          </button>

          <article className="news-card">
            {currentNews.image.src && (
              <div className="news-image-container">
                <img
                  src={currentNews.image.src}
                  alt={currentNews.image.alt || currentNews.title}
                  className="news-image"
                />
              </div>
            )}
            <div className="news-content">
              <h3>{currentNews.title}</h3>
              <p className="news-date">{currentNews.pubDate}</p>
              <p className="news-description">{currentNews.description}</p>
              <a 
                href={currentNews.link}
                target="_blank"
                rel="noopener noreferrer"
                className="read-more"
              >
                Lire la suite
              </a>
            </div>
          </article>

          <button 
            className="nav-button next" 
            onClick={goToNext}
            aria-label="Article suivant"
          >
            ›
          </button>
        </div>

        <div className="news-indicators">
          {news.map((_, index) => (
            <button
              key={index}
              className={`indicator ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
              aria-label={`Aller à l'article ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsCarousel;
